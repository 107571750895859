#ouiqueriesBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #008000;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#ouiqueriesBtn:hover {
    background: #99ff99;
}

#connectqueries, #listviewqueries, #viewdocqueries, #viewdisplayqueries, 
#editorqueries, #dynamictablequeries, #deskconqueries, #mobileconqueries{
    display: none;
}