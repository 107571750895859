#ouitasksBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #008000;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#ouitasksBtn:hover {
    background: #99ff99;
}

#newtasks, #createtasks, #doctasks, #previoustasks, #editdeletetasks, #changedeledittasks, 
#changetasks, #listtasks, #viewtasks, #listviewtasks, #viewdisplaytasks, #viewdoctasks, 
#changeviewtasks, #desktasks, #mobiletasks, #prevdesktasks, #prevmobiletasks, #viewdesktasks, #viewmobiletasks{
    display: none;
}