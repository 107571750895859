body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /*implement background color like couchDB*/
  background-color: rgba(255,255,255,0.1);
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*input[type=text], select {
  width: 90%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: large;
}*/

/*STYLING THE CREATED MODAL DOM ELEMENT*/
.modal{
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  /*width: 30rem;*/
  max-width: 80%;
  margin-left: 5%;
  z-index: 10;
  position: fixed;
  top: 30vh;
  /*left: calc(50% - 15rem);*/
}

.btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  margin: 0 1rem;
}

.btn--heure {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #306d1b;
  color: white;
  border: 1px solid #306d1b;
  margin: 0 1rem;
}

.btn:hover {
  background-color: #9c1458;
  border-color: #9c1458;
}

.btn--alt {
  background-color: transparent;
  color: #800040;
}

.btn--alt:hover {
  background-color: #f8dae9;
}

.btn--heure:hover {
  background-color: #f8dae9;
}

.backdrop {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.modalalert{
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: white;
  padding: 1rem;
  text-align: center;
  /*width: 30rem;*/
  max-width: 80%;
  margin-left: 5%;
  z-index: 10;
  position: fixed;
  top: 30vh;
  /*left: calc(50% - 15rem);*/
}

.btnalert {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #800040;
  color: white;
  border: 1px solid #800040;
  margin: 0 1rem;
}

.btnalert:hover {
  background-color: #9c1458;
  border-color: #9c1458;
}

.btnalert--alt {
  background-color: transparent;
  color: #800040;
}

.btnalert--alt:hover {
  background-color: #f8dae9;
}

.backdropalert {
  position: fixed;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.thin_table_config th {
  background-color: #999999;
  color: #ffffff;
  font-size: 17px;
}
.thin_table_config tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.26);
}
.thin_table_config td:first-child {
  width: 10%;
}
.thin_table_config td:nth-child(2) {
  width: 20%;
}
.thin_table_config th,
.thin_table_config td {
    border: 1px solid #656150;
    padding: 0.3em;
    font-size: 17px;
}
.thin_table_config {
  width: 98%;
}

#form_scroller{
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  /*scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;*/
  border-radius: 10;
}

#label_form{
  width: 50%;
  height: 30;
  color: #000000;
  font-size: 17px;
}

#texte_form{
  width: 90%;
  font-size: 18px;
  border-radius: 10px;
}

#number_form{
  width: 88%;
  height: 40px;
  font-size: 18px;
  border-radius: 10px;
}

#date_form{
  width: 90%;
  height: 46px;
  font-size: 18px;
  border-radius: 10px;
}

#button_edit{
  margin-top: 4%;
  flex: 1;
  width: 200px;
  height: 50px;
  text-align: center;
  align-content: center;
  background-color: #1a1aff;
  border: 1px solid #1a1aff;
  font-size: 18px;
  border-radius: 15px;
  color: #ffffff;
}

#button_delete{
  margin-top: 4%;
  flex: 1;
  width: 200px;
  height: 50px;
  text-align: center;
  align-content: center;
  background-color: #995c00;
  border: 1px solid #995c00;
  border-radius: 10px;
  font-size: 1.4em;
  color: #ffffff;
}

#button_add,#button_reset{
  margin-top: 4%;
  flex: 1;
  width: 200px;
  height: 50px;
  text-align: center;
  align-content: center;
  background-color: #306d1b;
  border: 1px solid #306d1b;
  border-radius: 10px;
  font-size: 1.4em;
  font-weight: bold;
  color: #ffffff;
}

#button_search{
  margin-top: 4%;
  flex: 1;
  width: 200px;
  height: 50px;
  text-align: center;
  align-content: center;
  background-color: #b3b300;
  border: 1px solid #b3b300;
  border-radius: 10px;
  font-size: 1.4em;
  color: #ffffff;
}

#table_scroller{
  display:flex;
  flex-direction:row;
}

.input_table{
  width: 90%;
  height: 25px;
  border-radius: 5px;
}

#result {
  border: 1px dotted rgb(189, 183, 183);
  padding: 3px;
}
#result ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#result ul li {
  padding: 5px 0;
  background-color: #eee;
}
#result ul li:hover {
  background: #a4ca97;
}

/*Create an Autocomplete Form style*/
.autocomplete{
  position: relative;
  display: inline-block;
}

.autocomplete-items{
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div{
  padding: 10px;
  cursor: pointer;
  background-color: #ffffff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover{
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active{
  background-color: DodgerBlue !important;
  color: #ffffff;
}

.resultsList{
  width:50%;
}


