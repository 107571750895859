#ouitablesBtn{
    margin-top: 4%;
    flex: 1;
    width: 120px;
    height: 35px;
    text-align: center;
    align-content: center;
    background-color: #008000;
    border: 1;
    border-radius: 15px;
    color: #ffffff;
}
#ouitablesBtn:hover {
    background: #99ff99;
}

#newtables, #createtables, #previoustables, #changetables, #listtables, #desktables, 
#mobiletables, #prevdesktables, #prevmobiletables{
    display: none;
}